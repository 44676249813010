export const environment = {
  BUILD_ENV: 'local',
  production: false,
  development: false,
  local: true,
  BASE_URL: 'https://api-stream-pf35w2ahoq-uc.a.run.app/',
  LUNA_CLIENT_ID: 'luna',

  keycloak: {
    authority: 'https://accounts.predigle.com',
    redirectUri: 'https://stream-dev.predigle.com/',
    postLogoutRedirectUri: 'https://stream-dev.predigle.com/',
    // redirectUri: 'http://localhost:4200',
    // postLogoutRedirectUri: 'http://localhost:4200',
    realm: 'predigle-dev',
    clientId: 'luna',
  },
  idleConfig: { idle: 10, timeout: 60, ping: 10 },
};