import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { StreamTree } from '../../../common/interface/common.interface';
import { LaneMonitor } from '../../../pages/live-console/interface/live-console.interface';
import { HistoryView } from '../../interface/common.interface';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private openSideNav = new BehaviorSubject<boolean>(false);
  public viewMode = new BehaviorSubject<boolean>(false);
  public streamID: string | undefined = undefined;
  public $streamID: Subject<string | undefined> = new Subject();
  public $changeStreamID: Subject<string | undefined> = new Subject();
  public isLoading: boolean = false;
  public searchProgress = new Subject<any>();
  public currentViewPage = new Subject<any>();
  public trackStreamTree: StreamTree[] = [];
  public progressType: Subject<string> = new Subject<string>();
  public viewChange: Subject<boolean> = new Subject<boolean>();
  public LaneMonitorLiveConsole: Subject<string[]> = new Subject<string[]>();
  public historyViewChecked: Subject<HistoryView> = new Subject<HistoryView>();

  constructor(private toastr: ToastrService) { }

  public toogleNav(status: boolean) {
    this.openSideNav.next(status);
  }

  public setLoader(status: boolean) {
    this.isLoading = status;
  }

  public setStreamID(id: string | undefined) {
    this.$streamID.next(id);
    this.streamID = id;
  }

  public changeStream(id: string | undefined) {
    this.$changeStreamID.next(id);
    this.streamID = id;
  }

  public toasterErrorMessage(message: string, type?: string) {
    this.toastr.error(message, type);
  }

  public toasterInfoMessage(message?: string, type?: string) {
    this.toastr.info(message, type);
  }
  public changeViewMode(status: boolean) {
    this.viewMode.next(status);
  }

  public searchProgressData(data: any) {
    this.searchProgress.next(data);
  }

  public updateCurrentViewPage(view: any) {
    this.currentViewPage.next(view);
  }

  public triggerViewChange(change: boolean) {
    this.viewChange.next(change);
  }

  public updateStreamTree(tree: StreamTree, type?: string) {
    if (type) {
      const index = this.trackStreamTree.findIndex(ele => ele.type === type);
      this.trackStreamTree.splice(index, 1);
    } else if (tree.type) {
      this.trackStreamTree.push(tree);
    }
  }

  public progressTypeHandler(type: string) {
    this.progressType.next(type);
  }

  public updateLaneMonitorLiveConsole(lanes: string[]) {
    this.LaneMonitorLiveConsole.next(lanes);
  }

  public UpdateHistoryViewChecked(view: HistoryView) {
    this.historyViewChecked.next(view);
  }
}